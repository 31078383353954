<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Viagem" : "Cadastrar Viagem" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card :class="`mb-${id ? '5' : '15'}`">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Dados Viagem</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="3">
              <span class="primary--text">Hora</span>
              <v-text-field
                placeholder="Informe a hora"
                class="mt-n1"
                v-model="dados.horario"
                required
                v-mask="'00:00'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <span class="primary--text">Descrição *</span>
              <v-text-field
                placeholder="Descrição"
                class="mt-n1"
                v-model="dados.descricao"
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Data de Virgência</span>
              <v-text-field
                placeholder="Informe a data de virgência"
                class="mt-n1"
                v-model="dados.data_viagem"
                required
                v-mask-date.br
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" v-if="dados.extra">
              <span class="primary--text">Data de Término</span>
              <v-text-field
                placeholder="Informe a dara de término"
                class="mt-n1"
                v-model="dados.data_termino"
                required
                v-mask-date.br
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Sentido</span>
              <v-select
                :items="['IDA', 'VOLTA']"
                v-model="dados.sentido"
                placeholder="Informe o tipo de serviço"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Tipo</span>
              <v-select
                :items="tiposervicos"
                v-model="dados.id_tipo_servico"
                item-text="tipo_servico"
                item-value="id"
                placeholder="Informe o tipo"
                class="mt-1 custom-solo-selection"
                solo
              />
            </v-col>
            <v-col cols="12" :sm="dados.extra ? '8' : '12'">
              <span class="primary--text">Gratuidades</span>
              <v-select
                item-value="id"
                item-text="descricao"
                v-model="dados.gratuidades"
                :items="gratuidades"
                multiple
                placeholder="Informe as gratuidades"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <span class="primary--text">Ônibus</span>
              <v-autocomplete
                item-value="id"
                item-text="descricao"
                v-model="dados.id_bus"
                :items="onibus"
                placeholder="Informe o ônibus"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Dias de Viagem</span>
              <v-select
                item-value="cod"
                item-text="dia"
                v-model="dados.dias"
                :items="[
                  { dia: 'Segunda-Feira', cod: '1' },
                  { dia: 'Terça-Feira', cod: '2' },
                  { dia: 'Quarta-Feira', cod: '3' },
                  { dia: 'Quinta-Feira', cod: '4' },
                  { dia: 'Sexta-Feira', cod: '5' },
                  { dia: 'Sábado', cod: '6' },
                  { dia: 'Domingo', cod: '0' },
                ]"
                multiple
                placeholder="Informe os dias de viagem"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>

            <v-col cols="12">
              <span class="primary--text"
                >Quantidade de poltronas bloqueadas para o SGVP MOTO</span
              >
              <v-text-field
                placeholder="Quantidade"
                class="mt-n1"
                v-model="dados.desabilitadas"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
              <v-checkbox
                v-model="dados.venda_em_pe"
                :label="'Venda em Pé'"
                color="primary"
                required
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                v-model="dados.extra"
                :label="'Viagem Extra'"
                color="primary"
                required
                disabled
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3">
              <v-checkbox
                v-model="dados.intermunicipal"
                :label="'Intermunicipal'"
                color="primary"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Confirmar
        </v-btn>
      </v-footer>
    </v-form>

    <v-card
      v-if="id"
      :class="`mb-${linesSelected.length > 0 || id ? '5' : '15'}`"
    >
      <v-card-title class="primary white--text d-flex justify-space-between">
        <span>Adicionar Linha</span>
      </v-card-title>

      <v-card-text class="pt-7 px-7">
        <v-row>
          <v-col cols="12" sm="12">
            <span class="primary--text">Selecione a(s) Linha(s):</span>
            <v-autocomplete
              :menu-props="{ top: true, offsetY: true }"
              v-model="lines"
              item-text="descricao"
              item-value="id"
              multiple
              chips
              :items="linhas"
              :disabled="disabled"
              placeholder="Informe as linhas"
              class="mt-1 custom-solo-selection"
              solo
            >
              <template slot="selection" slot-scope="{ item }"
                >{{ item.prefixo }} - {{ item.descricao }}</template
              >
              <template slot="item" slot-scope="{ item }"
                >{{ item.prefixo }} - {{ item.descricao }}</template
              >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="primary d-flex">
        <v-btn color="warning" depressed text @click="disabled = !disabled"
          >Desbloquear Linhas</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text @click="updateLines()"
          >Atualizar Horários</v-btn
        >
        <v-btn dark depressed text @click="addLines()" :disabled="disabled"
          >Adicionar Linhas</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card
      v-if="linesSelected.length > 0"
      :class="`mb-${showForm || id ? '5' : '15'}`"
    >
      <v-card-title class="primary white--text d-flex justify-space-between">
        <span>Linhas Adicionadas</span>
      </v-card-title>
      <v-card-text class="pt-7 px-7">
        <v-row>
          <v-col cols="12" sm="12">
            <v-expansion-panels focusable>
              <v-expansion-panel
                v-for="(item, index) in linesSelected"
                :key="index"
              >
                <div>
                  <v-expansion-panel-header @click="loadRotas(item.id)">{{
                    item.descricao
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list dense>
                      <v-list-item
                        v-for="(item, index) in rotas"
                        :key="index"
                        dense
                      >
                        <v-row align="center" dense>
                          <v-col cols="12" sm="6">{{ item.nome }}</v-col>

                          <v-col cols="12" sm="2">
                            <v-text-field
                              v-model="item.hora"
                              label="hh:mm"
                              required
                              v-mask="'00:00'"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2"></v-col>
                          <v-col cols="12" sm="2">
                            <v-switch
                              v-model="item.ativo"
                              :label="item.ativo ? 'Ativo' : 'Inativo'"
                              color="success"
                              inset
                            ></v-switch>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            v-if="index < rotas.length - 1"
                          >
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                    <br />
                    <v-footer absolute color="primary">
                      <v-btn
                        color="warning"
                        depressed
                        text
                        @click="invertOrderItens(rotas, item.id)"
                        >INVERTER ORDEM</v-btn
                      >
                      <div class="flex-grow-1"></div>
                      <v-btn dark text @click="updateHour(rotas)"
                        >CONFIRMAR</v-btn
                      >
                    </v-footer>
                  </v-expansion-panel-content>
                </div>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex primary justify-end">
        <v-btn dark depressed text @click="showForm = true"
          >Adicionar Conexões</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-if="showForm" :class="`mb-${id ? '5' : '15'}`">
      <v-card-title class="primary white--text d-flex justify-space-between">
        <span>Criar Conexão</span>
        <span>Campos Obrigatórios *</span>
      </v-card-title>

      <v-card-text class="pt-7 px-7">
        <v-row>
          <v-col cols="12" sm="12">
            <v-expansion-panels focusable>
              <v-expansion-panel
                v-for="(item, index) in linesSelected"
                :key="index"
              >
                <div>
                  <v-expansion-panel-header @click="loadRotas(item.id)">{{
                    item.descricao
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-3">
                    <v-row class="mb-5">
                      <v-col cols="12" sm="6">
                        <span class="primary--text">Origem</span>
                        <v-autocomplete
                          :items="rotas ? rotas : []"
                          v-model="conexao.id_localidade_origem"
                          item-value="id_localidade"
                          item-text="nome"
                          @change="conexao.id_linha = item.id"
                          placeholder="Informe a origem"
                          class="mt-1 custom-solo-selection"
                          solo
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <span class="primary--text">Destino</span>
                        <v-autocomplete
                          :items="rotas ? rotas : []"
                          v-model="conexao.id_localidade_destino"
                          item-value="id_localidade"
                          item-text="nome"
                          @change="conexao.id_linha = item.id"
                          placeholder="Informe o destino"
                          class="mt-1 custom-solo-selection"
                          solo
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-footer absolute color="primary">
                      <div class="flex-grow-1"></div>
                      <v-btn dark depressed text @click="addConexoes()"
                        >Adicionar</v-btn
                      >
                    </v-footer>
                  </v-expansion-panel-content>
                </div>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="id" class="mb-15">
      <v-card-title class="primary white--text d-flex justify-space-between">
        <span>Conexões Criadas</span>
      </v-card-title>

      <v-card-text v-if="id" class="pt-7 px-7">
        <v-row v-if="!conexoes.result">
          <v-col cols="12" sm="12">
            <v-expansion-panels focusable>
              <v-expansion-panel v-for="(conect, i) in conexoes" :key="i">
                <v-expansion-panel-header
                  >{{ conexoes ? conect.origem_inicial.nome : null }} X
                  {{
                    conexoes ? conect.destino_final.nome : null
                  }}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-list two-line style="width: 100%" class="mb-5">
                    <v-list-item>
                      <v-list-item-content class="d-flex" cols="12" sm="6">
                        <v-list-item-title>
                          ORIGEM
                          <v-icon small>mdi-arrow-right</v-icon>DESTINO
                        </v-list-item-title>
                        <v-list-item-subtitle class="mt-2">
                          {{ conect.origem_inicial.nome }}
                          <v-icon small>mdi-arrow-right</v-icon>
                          {{ conect.destino_inicial.nome }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="d-flex" cols="12" sm="6">
                        <v-list-item-title>
                          ORIGEM
                          <v-icon small>mdi-arrow-right</v-icon>DESTINO
                        </v-list-item-title>
                        <v-list-item-subtitle class="mt-2">
                          {{ conect.origem_final.nome }}
                          <v-icon small>mdi-arrow-right</v-icon>
                          {{ conect.destino_final.nome }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-footer absolute color="primary">
                    <div class="flex-grow-1"></div>
                    <v-btn
                      dark
                      depressed
                      text
                      @click="dropConnection(conect.id)"
                      >Excluir</v-btn
                    >
                  </v-footer>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="d-flex" cols="12" sm="12">
            <h3
              class="font-weight-light"
              style="position: relative; left: 20px"
            >
              {{ conexoes.result }}
              <v-btn
                color="primary"
                depressed
                text
                @click="showForm = true"
                class="ml-2"
                >Adicionar Conexões</v-btn
              >
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      disabled: true,
      gridLoader: false,
      loading: false,
      menu: false,
      showForm: false,
      conexoes: [],
      conections: [],
      selected_connections: [],
      linhas: [],
      lines: [],
      linesSelected: [],
      rotas: [],
      gratuidades: [],
      onibus: [],
      conexao: {
        id_localidade_origem: 0,
        id_localidade_destino: 0,
        id_viajem: this.id,
        id_linha: 0,
      },
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      headers: [
        { text: "Localidade", value: "localidade", sortable: false },
        { text: "Hora", value: "hora_saida", sortable: false },
      ],
      dados: {
        horario: "",
        descricao: "",
        sentido: 0,
        venda_em_pe: false,
        data_viagem: null,
        id_tipo_servico: null,
        dias: [],
        extra: false,
        desabilitadas: "0",
        gratuidades: [],
        id_bus: [],
        data_termino: null,
        intermunicipal: false,
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  created() {
    this.loadLines();
    this.loadGratuidades();
    this.loadBus();
    this.tiposDeServico();
    if (this.id) {
      this.loader(this.id);
      this.loadLinesAdd(this.id);
      this.loadConnectionsAdd();
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    loadGratuidades() {
      this.$http
        .get(`/gratuidades`)
        .then((r) => {
          this.gratuidades = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loader(id) {
      if (id) {
        this.$store.dispatch("activeLoader", true);
        this.$http
          .get(`/viagens/${id}`)
          .then((r) => {
            this.dados = r.data[0];
            this.dados.intermunicipal =
              r.data[0].intermunicipal === "0" ? false : true;
            this.dados.dias = JSON.parse(this.dados.dias);
            this.dados.gratuidades = JSON.parse(this.dados.gratuidades);
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    loadRotas(id) {
      this.rotas = [];
      this.$http
        .get(`/viagens/rotas/${this.id}/${id}`)
        .then((r) => {
          this.rotas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    tiposDeServico() {
      this.$http
        .get(`/tiposservico`)
        .then((r) => {
          this.tiposervicos = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadBus() {
      this.$http
        .get(`/bus`)
        .then((r) => {
          this.onibus = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadLines() {
      this.$http
        .get(`/linhas`)
        .then((r) => {
          this.linhas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadLinesAdd() {
      this.$http
        .get(`/viagens/linhas/${this.id}`)
        .then((r) => {
          this.$store.dispatch("activeLoader", false);
          this.linesSelected = r.data;
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadConnectionsAdd() {
      this.$http
        .get(`/viagens/connections/${this.id}`)
        .then((r) => {
          this.$store.dispatch("activeLoader", false);
          this.conexoes = r.data;
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    addConexoes() {
      this.selected_connections.push({
        id_localidade_origem: this.conexao.id_localidade_origem,
        id_localidade_destino: this.conexao.id_localidade_destino,
        id_linha: this.conexao.id_linha,
      });
      if (this.selected_connections.length > 1) {
        this.$http
          .post(
            `/viagens/create/connections/${this.id}`,
            this.selected_connections
          )
          .then((r) => {
            if (r) {
              this.$store.dispatch("activeLoader", false);
              (this.conexao.id_localidade_origem = 0),
                (this.conexao.id_localidade_destino = 0),
                (this.conexao.id_linha = 0);
              this.selected_connections = [];
              this.loadConnectionsAdd();
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.message.text = "Primeiro Trecho Adicionado";
        this.message.snacshow = true;
        this.message.type = "success";
      }
    },
    addLines() {
      this.$http
        .post(`/viagens/create/lines/${this.id}`, this.lines)
        .then((r) => {
          if (r) {
            this.$store.dispatch("activeLoader", false);
            this.loadLinesAdd();
            this.addLocationsforHour();
            this.addLocations();
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    updateLines() {
      this.dados.dias = JSON.stringify(this.dados.dias);
      this.dados.gratuidades = JSON.stringify(this.dados.gratuidades);
      this.$http
        .post(`/viagens/update/${this.id}`, this.dados)
        .then((r) => {
          if (r) {
            this.$http
              .post(`/viagens/create/lines/${this.id}`, this.lines)
              .then((r) => {
                if (r) {
                  this.$store.dispatch("activeLoader", false);
                  this.loadLinesAdd();
                  this.addLocationsforHour();
                  this.dados.dias = JSON.parse(this.dados.dias);
                  this.dados.gratuidades = JSON.parse(this.dados.gratuidades);
                }
              })
              .catch((e) => {
                this.$store.dispatch("activeLoader", false);
                this.message.text = e;
                this.message.snacshow = true;
                this.message.type = "success";
              });
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    addLocationsforHour() {
      this.$http
        .post(
          `/viagens/create/viajens/hour/${this.id}/${this.dados.sentido}`,
          this.lines
        )
        .then((r) => {
          if (r) {
            this.$store.dispatch("activeLoader", false);
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    addLocations() {
      this.$http
        .post(
          `/viagens/create/locations/${this.id}/${this.dados.sentido}`,
          this.lines
        )
        .then((r) => {
          if (r) {
            this.$store.dispatch("activeLoader", false);
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    updateHour(item) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/viagens/update/viajens/hour`, item)
        .then((r) => {
          console.log(item);
          this.$store.dispatch("activeLoader", false);
          this.message.text = r.data.success;
          this.message.snacshow = true;
          this.message.type = "success";
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    invertOrderItens(item, id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/viagens/invert/viajens/itens/${this.id}`, item)
        .then((r) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = r.data.success;
          this.message.snacshow = true;
          this.message.type = "success";
          this.loadRotas(id);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    dropConnection(id) {
      let c = confirm("Deseja excluir essa conexão?");
      if (c) {
        this.$http
          .post(`/viagens/drop/connections/${id}`)
          .then((r) => {
            if (r) {
              this.$store.dispatch("activeLoader", false);
              this.loadConnectionsAdd();
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    salvar() {
      this.dados.dias = JSON.stringify(this.dados.dias);
      this.dados.gratuidades = JSON.stringify(this.dados.gratuidades);
      if (this.id) {
        this.$http
          .post(`/viagens/update/${this.id}`, this.dados)
          .then((r) => {
            if (r) {
              this.$store.dispatch("activeLoader", false);
              this.dados.dias = JSON.parse(this.dados.dias);
              this.dados.gratuidades = JSON.parse(this.dados.gratuidades);
            }
            this.$router.push("/cadastros-operacionais/viagens/gerenciar");
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post(`/viagens/create`, this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            this.id = r.data.id;
            this.dados.dias = JSON.parse(this.dados.dias);
            this.dados.gratuidades = JSON.parse(this.dados.gratuidades);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
